import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const SectionWrap = styled.section`
  padding: 2rem 0 7rem;

  @media (max-width: 992px) {
    overflow: hidden;
  }

  @media (max-width: 767px) {
    text-align: center;
  }
`;

const BannerHeading = styled.h2``;

const BannerSubHeading = styled.h4`
  color: #282828 !important;
  font-size: 20px;
`;

const BannerPara = styled.p`
  color: #424242 !important;
  font-family: ProximaNova-Regular;
  font-size: 18px !important;
  line-height: 28px;
`;

const ImageWrap = styled.div`
  position: relative;
  @media (max-width: 767.98px) {
    margin-bottom: 2rem;

    &::before,
    &::after {
      content: '';
      height: 187px;
      position: absolute;
      top: -90px;
      z-index: -9;
    }

    &::before {
      background-color: #db0163;
      right: -56px;
      width: 114px;
    }

    &::after {
      background-color: #c799d1;
      right: 75px;
      width: 26px;
    }
  }
`;

const StyledH3 = styled.h3`
  color: #282828 !important;
  font-family: ProximaNova-Semibold !important;
  font-size: 24px;
`;

const Media = styled.div``;

const MediaBody = styled.div`
  flex: 1;
`;

const MediaSubHeading = styled.p`
  font-family: ProximaNova-Regular;
  color: #282828;
`;

const MediaPara = styled.p`
  font-family: ProximaNova-Regular;
`;

const StyledHr = styled.hr`
  height: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: transparent;
`;

const ImpactAccountSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className='justify-content-center'>
          <Col md={10}>
            {/* Row Impact Account */}
            <Row className='justify-content-center'>
              <Col
                md={6}
                className='order-1 order-md-0'
                data-sal='fade'
                data-sal-duration='1000'
                data-sal-delay='100'>
                <BannerHeading>Impact Account</BannerHeading>
                <BannerSubHeading>Free to open and use</BannerSubHeading>
                <BannerPara className='mb-sm-0'>
                  Charitable Impact doesn’t charge sign-up or transaction fees
                  for Impact Accounts, and no fees are charged on the balance in
                  your account.
                </BannerPara>
              </Col>

              <Col
                md={6}
                data-sal='fade'
                data-sal-duration='1000'
                data-sal-delay='500'>
                <ImageWrap>
                  <img
                    className='img-fluid'
                    src='/fees/fees-impact-account-img.jpg'
                    alt=''
                    loading='lazy'
                  />
                </ImageWrap>
              </Col>
            </Row>

            <StyledHr className='mt-4 mb-4' />

            {/* Row Bank And Credit */}
            <Row className='mt-5 mt-sm-0'>
              <Col
                md={6}
                data-sal='fade'
                data-sal-duration='1000'
                data-sal-delay='100'>
                <StyledH3>
                  Processing fees from banks and credit card companies
                </StyledH3>
                <BannerPara>
                  Banks and credit card companies charge charities, including
                  Charitable Impact, a fee to process donations. If you use a
                  credit card to donate, the fee is 2.8%. We recover this fee
                  when you send a gift to charity from your Impact Account.
                </BannerPara>
              </Col>

              <Col
                md={6}
                data-sal='fade'
                data-sal-duration='1000'
                data-sal-delay='500'>
                <Media className='d-flex align-items-start'>
                  <MediaBody>
                    <MediaSubHeading>
                      More money can get to charity when you give with
                      Charitable Impact
                    </MediaSubHeading>
                    <MediaPara>
                      Our credit card processing fee is lower when compared to
                      most charities because we process higher volumes of
                      donations. This means that more money can get to charities
                      when you give from your Impact Account.
                    </MediaPara>
                  </MediaBody>
                </Media>

                <Media>
                  <MediaBody>
                    <MediaSubHeading>
                      Processing fees don't affect your tax receipt
                    </MediaSubHeading>
                    <MediaPara>
                      You'll receive a tax receipt for 100% of the amount you
                      add to your Impact Account.
                    </MediaPara>
                  </MediaBody>
                </Media>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default ImpactAccountSection;
