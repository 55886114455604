import React from "react";
import BannerSection from "../components/Fees/BannerSection";
import ImpactAccountSection from "../components/Fees/ImpactAccountSection";
import PricingForm from "../components/Fees/PricingForm";
import PricingTableSection from "../components/Fees/PricingTableSection";
import Layout from "../components/Layout/Layout";

const pageInfo = {
  parent_path: "/about",
  path: "/fees",
  title: "The most cost-effective way to give in Canada",
  description:
    "Charitable Impact doesn't charge sign-up or transaction fees for Impact Accounts. And, when you give from your Impact Account, more money can get to charities.",
};

const Fees = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <BannerSection />
      <ImpactAccountSection />
      <PricingTableSection />
      <PricingForm />
    </Layout>
  );
};

export default Fees;
