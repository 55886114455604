import React from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

const SectionWrap = styled.section`
  background-color: #f5f8fc;
  padding: 80px 0;
  text-align: center;

  .badge {
    border-radius: 20.5px;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    padding: 11px 47px;
    text-align: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      background-color: #0062cc !important;
    }
  }
`;

const ProcessingFees = styled.div`
  margin: 0 auto;
  width: 80%;

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

const StyledH2 = styled.h2`
  color: #282828;
  font-weight: 600;
  line-height: 37px;
  margin: 0 auto 30px;
  width: 70%;
  font-size: 30px;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

const StyledPara = styled.p`
  color: #282828;
  font-size: 16px;
  font-family: ProximaNova-Regular;
`;

const StyledCol = styled(Col)`
  margin: 0 auto;
  max-width: 706px;
`;

const PricingTableSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className='justify-content-center'>
          <ProcessingFees>
            <Col md={12}>
              <Row>
                <Col
                  md={12}
                  data-sal='fade'
                  data-sal-duration='1000'
                  data-wow-delay='100'>
                  <StyledH2>
                    {/* What are the processing fees charged by banks and credit
                    card companies? */}
                    How can I see how much the processing fee is for my gift?
                  </StyledH2>
                </Col>

                <StyledCol
                  sm={12}
                  data-sal='fade'
                  data-sal-duration='1000'
                  data-sal-delay='500'>
                  <StyledPara>
                    You'll see the specific processing fee charged by banks and
                    credit card companies when you send a gift to charity from
                    your Impact Account.
                    {/* You'll see the specific processing fee charged by banks and
                    credit card companies when you send gifts to charities from
                    your Impact Account. This fee is often lower than what the
                    charity would pay their own bank or credit card processor to
                    accept a gift directly from a donor. */}
                  </StyledPara>
                  {/* <StyledPara>
                    Processing fees vary depending on the payment method
                    originally used to add the money you're giving. The most
                    common way of adding money to an Impact Account is by credit
                    card, and the fee set by Charitable Impact's credit card
                    processor is 2.8%—lower than most other charity websites.
                  </StyledPara> */}
                  <Badge
                    as='a'
                    bg='primary'
                    href='https://help.charitableimpact.com/article/136-fees'
                    target='_blank'
                    className='mt-4'>
                    Learn more about fees
                  </Badge>
                </StyledCol>
              </Row>
            </Col>
          </ProcessingFees>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default PricingTableSection;
