import React from 'react';
import styled from 'styled-components';
import ButtonWithAnchor from '../Buttons/ButtonWithAnchor';
import { Col, Container, Row, Table } from 'react-bootstrap';

const SectionWrap = styled.section`
  overflow: hidden;
  position: relative;
  padding: 80px 0;
`;

const TopContent = styled.div`
  text-align: center;
  position: relative;
  z-index: 999;
`;

const StyledH2 = styled.h2``;

const StyledPara = styled.p`
  color: #282828;
  font-family: ProximaNova-Regular;
  font-size: 18px;
  margin: 0 auto 20px;
  max-width: 660px;
`;

const CardWrapper = styled.div`
  margin: auto;
  width: 70%;

  @media only screen and (max-width: 991px) {
    width: 80%;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

const CardContainer = styled.div`
  background-color: #f5f8fc;
  border-radius: 6px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.04);
  height: 100%;
  overflow: hidden;
  padding: 29px 24px;
  position: relative;

  &::before {
    background-color: #27ccd1;
    bottom: 0;
    content: '';
    height: 8px;
    position: absolute;
    right: 0;
    width: 100%;
  }

  &.border-purple {
    &::before {
      background-color: #663387;
    }
  }
`;

const CardTitle = styled.h4`
  color: #663387;
  font-family: ProximaNova-Medium;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: left;
`;

const CardContent = styled.p`
  color: #424242;
  font-family: ProximaNova-Regular;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
`;

const FeesTableWrap = styled.div`
  position: relative;

  @media (min-width: 450px) {
    padding: 0 15px;
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    background-position: 50%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  &::before {
    background-image: url(/fees/shapes.png);
    height: 277px;
    right: -121px;
    top: 59px;
    width: 201px;
    z-index: -9;
  }

  &::after {
    background-image: url(/fees/shape.png);
    bottom: -43px;
    height: 263px;
    left: -125px;
    width: 267px;
  }
`;

const FeesTableContent = styled.div`
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: 2px 2px 19px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 2px 2px 19px 0 rgba(0, 0, 0, 0.07);
  margin: 50px 0 30px;
  padding: 45px 65px;
  position: relative;

  &::before {
    background-image: url(/fees/dots.png);
    background-position: 50%;
    background-repeat: no-repeat;
    content: '';
    height: 270px;
    position: absolute;
    right: -56px;
    top: -126px;
    width: 240px;
    z-index: -9;

    @media (max-width: 992px) {
      display: none;
    }
  }

  @media (max-width: 630px) {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 48px 0 18px;
    padding: 45px 15px;
  }
`;

const FeesCheckList = styled.div`
  border-bottom: 1px solid #009ea1;
  margin-bottom: 30px;
  padding-bottom: 30px;
`;

const StyledH3 = styled.h3`
  font-family: ProximaNova-Semibold;
  font-size: 24px;
  margin-bottom: 30px;
  color: #282828;
`;

const StyledSubText = styled.p`
  color: #424242;
  font-family: ProximaNova-Regular;
`;

const StyledUlFlex = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`;

const StyledLi = styled.li`
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: ProximaNova-Regular;
  font-size: 16px;
  line-height: 1;
  list-style: none;
  padding: 0.5rem 0;
  width: 33%;

  &::before {
    content: url(/fees/tick.jpg);
  }

  span {
    margin-left: 0.5rem;
  }

  @media (max-width: 767px) {
    width: 50%;
  }

  @media (max-width: 630px) {
    width: 100%;
  }
`;

const FeesTable = styled.div`
  tbody {
    border-top: none !important;
  }
`;

const StyledTh = styled.th`
  border-bottom: 1px solid #9142a1;
  border-top: 0;
  font-family: ProximaNova-Regular;
  font-size: 18px;
  padding: 1rem 0 !important;

  .Charitable-heading {
    font-family: ProximaNova-Semibold;
    font-size: 18px;
  }
`;

const StyledTd = styled.td`
  border-bottom: 1px solid #9142a1 !important;
  border-top: 0;
  font-family: ProximaNova-Regular;
  font-size: 18px;
  padding: 1rem 0 !important;

  &.free_wapper {
    text-align: right;
  }
`;

const FeesNotes = styled.div``;

const DonationBtn = styled.div`
  display: table;
  margin: 48px auto auto;
`;

const StyledButtonWithAnchor = styled(ButtonWithAnchor)`
  background-color: rgb(0 123 255);
  font-weight: 700;
  padding: 11px 47px;
  line-height: 20px;

  &::before {
    display: none;
  }

  @media (max-width: 767px) {
    padding: 11px 20px;
  }
`;

const PricingForm = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <TopContent
            data-sal='fade'
            data-sal-duration='1000'
            data-sal-delay='1000'>
            <Col sm={12}>
              <StyledH2>Charitable investment and non-cash assets</StyledH2>
              <StyledPara>
                With Charitable Impact, you can choose to Donate & Invest,
                Donate & Give, or do a combination of both. In each case, you
                can take advantage of immediate tax benefits while enjoying more
                time and space to plan your impact.
              </StyledPara>
            </Col>

            <CardWrapper>
              <Row>
                <Col md={6} sm={12} className='mb-3'>
                  <CardContainer>
                    <CardTitle>Donate &amp; Invest</CardTitle>
                    <CardContent>
                      Donate and invest a wide range of assets using a
                      Charitable Investment Account, potentially growing the
                      value over time while you decide which charities to
                      support.
                    </CardContent>
                  </CardContainer>
                </Col>

                <Col md={6} sm={12} className='mb-3'>
                  <CardContainer className='border-purple'>
                    <CardTitle>Donate &amp; Give</CardTitle>
                    <CardContent>
                      Donate non-cash assets and liquidate them now to fund your
                      Impact Account, where you can give to the charities of
                      your choice whenever you like.
                    </CardContent>
                  </CardContainer>
                </Col>
              </Row>
            </CardWrapper>
          </TopContent>

          <Col sm={12}>
            <FeesTableWrap>
              <FeesTableContent>
                {/* Check List Options */}
                <FeesCheckList>
                  <StyledH3 className='mb-0'>
                    Securities, cash, and more
                  </StyledH3>
                  <StyledSubText>
                    We accept a wide range of assets for donation:
                  </StyledSubText>

                  <StyledUlFlex>
                    <StyledLi>
                      <span>Cash</span>
                    </StyledLi>
                    <StyledLi>
                      <span>Publicly-traded securities</span>
                    </StyledLi>
                    <StyledLi>
                      <span>Private company shares</span>
                    </StyledLi>
                    <StyledLi>
                      <span>Life insurance</span>
                    </StyledLi>
                    <StyledLi>
                      <span>Real estate</span>
                    </StyledLi>
                  </StyledUlFlex>
                </FeesCheckList>

                {/* Fees Amount Table */}
                <FeesTable>
                  <StyledH3 className='mb-0'>
                    Charitable Investment Account fees
                  </StyledH3>
                  <StyledSubText className='mt-2'>
                    Charitable Impact offers the lowest cost of any
                    advisory-managed donor-advised fund in Canada. Fees do not
                    impact the tax receipt, which is determined by the fair
                    market value of the assets at the time of donation.
                  </StyledSubText>

                  {/* Table */}
                  <Table>
                    <thead>
                      <tr>
                        <StyledTh className='Donate_Invest_wapper'>
                          <span className='Charitable-heading'>
                            Account balance
                          </span>{' '}
                        </StyledTh>
                        <StyledTh className='text-end'>
                          <span className='Charitable-heading'>
                            Annual fee
                          </span>
                        </StyledTh>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <StyledTd className='Donate_Invest_wapper'>
                          First $1,000,000
                        </StyledTd>
                        <StyledTd className='value free_wapper'>0.50%</StyledTd>
                      </tr>
                      <tr>
                        <StyledTd className='Donate_Invest_wapper'>
                          Assets above first $1,000,000
                        </StyledTd>
                        <StyledTd className='value free_wapper'>0.25%</StyledTd>
                      </tr>
                      <tr>
                        <StyledTd className='Donate_Invest_wapper'>
                          Total assets above $5,000,000
                        </StyledTd>
                        <StyledTd className='value free_wapper'>
                          Contact Charitable Impact for details
                        </StyledTd>
                      </tr>
                    </tbody>
                  </Table>

                  {/* Notes */}
                  <FeesNotes>
                    {/* <StyledH4 className="mb-1 mt-2">
                      Important notes about these fees
                    </StyledH4> */}
                    <StyledSubText>
                      Our fee is collected quarterly and is based on the average
                      of the opening and closing market value of the Charitable
                      Investment Account balance each quarter. Our fee is
                      charged in addition to any fees charged directly by
                      financial advisors.
                    </StyledSubText>
                    <StyledSubText>
                      Get in touch for specific information about fees for
                      donations of private company shares, life insurance, and
                      real estate.
                    </StyledSubText>
                    <DonationBtn>
                      <StyledButtonWithAnchor
                        text='Contact us to learn more or begin a donation'
                        href='/contact'
                        target='_blank'
                      />
                    </DonationBtn>
                  </FeesNotes>
                </FeesTable>
              </FeesTableContent>
            </FeesTableWrap>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default PricingForm;
