import React, { useEffect, useState } from "react";
import styled from "styled-components";

const SectionWrap = styled.section`
  min-height: 480px;
  position: relative;
  width: 100%;

  &:: before {
    content: "";
    background-image: url(/fees/dot-tabe.png);
    background-position: 50%;
    background-repeat: no-repeat;
    margin: auto;
    position: absolute;
    top: 260px;
    left: 220px;
    bottom: auto;
    width: 50px;
    height: 50px;

    @media (max-width: 630px) {
      bottom: 50px;
      left: 0;
      right: 0;
      top: 180px;
    }
  }

  @media (max-width: 992px) {
    min-height: 420px;
    overflow: hidden;
  }
`;

const BannerLeft = styled.div`
  left: 0;
  position: relative;
  width: 50%;
`;

const BannerLeftImg1 = styled.div`
  float: left;
  height: 384px;
  margin-top: -44px;
  position: relative;
  width: 201px;

  img {
    width: 100%;

    @media (max-width: 1199px) {
      height: 320px;
      -o-object-fit: cover;
      object-fit: cover;
      width: 80%;
    }

    @media (max-width: 630px) {
      height: 180px;
      width: 52%;
    }
  }

  @media (max-width: 1199px) {
    height: auto;
    margin-top: -34px;
    width: 180px;
  }
`;

const BannerLeftMobile = styled.span`
  @media (max-width: 630px) {
    background-color: #82e3e0;
    height: 146px;
    left: 104px;
    position: absolute;
    width: 30px;
  }
`;

const BannerLeftIcon = styled.div`
  float: left;
  height: 212px;
  position: absolute;
  top: 204px;
  width: 160px;

  img {
    width: 100%;
  }

  @media (max-width: 1199px) {
    float: left;
    height: 232px;
    position: absolute;
    top: 162px;
    width: 140px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const BannerContent = styled.div`
  display: inline-block;
  left: 0;
  margin: 90px auto auto;
  max-width: 460px;
  padding: 1rem;
  position: absolute;
  right: 0;
  z-index: 99;

  @media (max-width: 992px) {
    max-width: 340px;
  }

  @media (max-width: 630px) {
    display: inline-block;
    margin-top: 180px;
    max-width: 300px;
    padding: 0;
  }
`;

const BannerHeading = styled.h1`
  color: #424242;
  font-family: ProximaNova-Light;
  font-size: 40px;

  @media (max-width: 992px) {
    font-size: 30px;
    text-align: center;
  }
`;

const BannerRight = styled.div`
  float: right;
  position: relative;
  right: 0;
  width: 50%;

  @media (max-width: 630px) {
    width: 29%;
  }
`;

const BannerRightImg1 = styled.div`
  float: right;
  height: 305px;
  position: relative;
  width: 275px;
  text-align: right;

  img {
    width: 100%;
    @media (max-width: 1199px) {
      height: 260px;
      -o-object-fit: cover;
      object-fit: cover;
      width: 50% !important;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const BannerRightImg2 = styled.div`
  float: right;
  height: 299px;
  margin-right: 15px;
  margin-top: -76px;
  position: relative;
  width: 60px;

  @media (max-width: 1199px) {
    margin-right: -130px;
  }

  @media (max-width: 767px) {
    margin-right: 0;
    margin-top: -26px;
  }

  @media (max-width: 630px) {
    margin-top: -153px;
    position: absolute;
  }

  @media (max-width: 570px) {
    left: -236px;
  }

  @media (max-width: 446px) {
    left: -105px;
  }

  @media (max-width: 345px) {
    left: -85px;
  }
`;

const BannerRightIcon = styled.div`
  float: right;
  height: auto;
  position: absolute;
  right: 200px;
  top: 0;
  width: 233px;

  @media (max-width: 1199px) {
    right: 80px;
    top: -20px;
    width: 160px;
  }

  @media (max-width: 767px) {
    right: -100px;
  }

  @media (max-width: 446px) {
    float: right;
    height: auto;
    position: absolute;
    right: 80px;
    right: -130px;
    top: 60px;
    width: 200px;
  }
`;

const BannerSection = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <SectionWrap id="c-banner">
      {/* Banner Left */}
      <BannerLeft style={{ transform: `translateY(-${offsetY * 2}px)` }}>
        <BannerLeftImg1
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="100"
        >
          <img src="/fees/fees-left-banner.jpg" alt="" loading="lazy" />
        </BannerLeftImg1>
        <BannerLeftMobile
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="200"
        />
        <BannerLeftIcon
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="300"
        >
          <img src="/fees/shape.png" alt="" loading="lazy" />
        </BannerLeftIcon>
      </BannerLeft>

      {/* Banner Content */}
      <BannerContent id="bannerContent">
        <BannerHeading>
          The most cost-effective way to give in Canada
        </BannerHeading>
      </BannerContent>

      {/* Banner Right */}
      <BannerRight style={{ transform: `translateY(-${offsetY * 2}px)` }}>
        <BannerRightImg1
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="400"
        >
          <img src="/fees/photo.jpg" alt="" loading="lazy" />
        </BannerRightImg1>
        <BannerRightImg2
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="500"
        >
          <img src="/fees/photo-right.jpg" alt="" loading="lazy" />
        </BannerRightImg2>
        <BannerRightIcon
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="600"
        >
          <img src="/fees/shape-icons.png" alt="" loading="lazy" />
        </BannerRightIcon>
      </BannerRight>
    </SectionWrap>
  );
};

export default BannerSection;
